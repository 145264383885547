<template>
	<div class="error_area">
		<top-search @changeMode="changeMode" :isHelp="true"></top-search>
		<div class="error-wrap">
			<div class="status">404</div>
			<div class="status_text">很抱歉，您要找的页面不见了！</div>
		</div>
	</div>
</template>

<script>
import topSearch from '@/components/top_search';
export default {
	name: 'error',
	components: {
		topSearch
	},
	data: () => {
		return {
			current_mode:1,
		};
	},
	created() {},
	methods: {
		changeMode(mode){
			this.current_mode = mode;
		},
	}
};
</script>
<style lang="scss" scoped>

.error_area{
	padding-top: 30px;
}
.error-wrap {
	padding: 232px 0;
	margin-top: 30px;
	background-color: #fff;
	border-radius: 10px;
	text-align: center;
	.status{
		font-size: 65px;
		font-weight: 600;
		line-height: 14px;
	}
	.status_text{
		margin-top: 70px;
		font-size: 18px;
		line-height: 14px;
	}
}
</style>
